body {
}
.App {
  text-align: center;
  font-family: 'Comic Sans MS', cursive, sans-serif;
}

.App-logo {
  height: 40vmin;
}

.App-link {
  color: #09d3ac;
}

/* .mahogny {
  background-image: url('../assets/mahogny.png');
  background-color: initial;
} */
